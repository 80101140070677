.social-contact{
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-icon-div{
    padding: 6px;
    cursor: pointer;
    margin: 6px;
}
.social-icon{
    height: 40px;
}

@media only screen and (max-width: 720px){
     .social-icon{
         height: 32px;
     }
}